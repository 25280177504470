
/* Light-blue Light scheme (Default) */
/* Can be forced with data-theme="light" */
[data-theme="light"],
:root:not([data-theme="dark"]) {
  --primary: #13c265;
  --primary-hover: rgb(17, 163, 85);
  --primary-focus: rgba(19, 194, 101, 0.125);
  --primary-inverse: #FFF;
}

html{
  background-color: #E5E5E5;
}

nav, footer {
  background-color: var(--background-color);
  box-shadow: -24px 0px 80px rgba(49, 79, 124, 0.02);
}

.fade-in-top {
	-webkit-animation: fade-in-top .5s ease-in-out both;
	        animation: fade-in-top .5s ease-in-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-1 14:32:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
